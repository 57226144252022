<template>
  <section>
    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>Clientes</h1>

        <v-btn color="primary" :to="{ name: 'customer-form' }">
          <v-icon left>mdi-plus</v-icon>
          Agregar
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          hide-details
          single-line
        />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="options.itemsPerPage"
        :loading="loading"
        :options.sync="options"
        :search="search"
        :server-items-length="total"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="info"
                :to="{ name: 'customer-detail', params: { uuid: item.uuid } }"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Detalle</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="warning"
                :to="{ name: 'customer-form', params: { uuid: item.uuid } }"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="handleDelete(item)"
                small
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Eliminar</v-card-title>

        <v-card-text>
          ¿Desea eliminar al empleado <b>{{ selectedItem.full_name | uppercase }}</b
          >?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="darken-1" text @click="dialog = false"> Cancelar </v-btn>

          <v-btn color="red darken-1" text @click="remove"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
// API
import CustomerAPI from '@/api/customer';

// Mixins
import TableMixin from '@/mixins/TableMixin';

export default {
  name: 'CustomerList',

  mixins: [TableMixin],

  data: () => ({
    headers: [
      { text: 'Sucursal', value: 'office_object.name' },
      { text: 'Nombre', value: 'first_name' },
      { text: 'Apellido Paterno', value: 'last_name' },
      { text: 'Apellido Materno', value: 'mother_last_name' },
      { text: 'RFC', value: 'rfc' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),

  async created() {
    this.api = new CustomerAPI();
    await this.list();
  },
};
</script>

<style lang="scss" scoped></style>
